import PushServerClient from "pushserver-client/dist/pushServerClient";

import {
    getMISLoadBalancedWebSocketHost,
    getMISTypeAndEnvironment
} from "../../../general/js/es6_statics/constants.js";
class socket {

    constructor() {
        // STATIC CLASS
    }

    static checkWebSocket() {
        return ("WebSocket" in window && !("__addTask" in WebSocket)) ||
            "MozWebSocket" in window;
    }

    static checkSocketConnection(options) {

        // determine proper target host:
        const { environment, misType } = getMISTypeAndEnvironment();
        const targetHost = getMISLoadBalancedWebSocketHost(environment);

        Login.client = new PushServerClient({
            alternatePort: 1030,
            autoReconnect: false,
            connectTimeout: 5000,
            standartPort: 80,
            host: targetHost,
            onConnected: options.OnConnected
        });

        Login.client.on("connected", options.OnConnected);

        window.setTimeout(function () {
            if (!Login.connected && options.OnFailure) {
                options.OnFailure();
            }
        }, 10000);
    }
}

export default socket;
