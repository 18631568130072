import { SPORTS } from "../../../solutions/libs/stuffjs/statics/enumerations.js";
import { getAuthenticationId } from "../../general/js/es6_statics/constants.js";
import { isInternetExplorer } from "../../general/js/libs/jetuniversalcomponents/utils_es6/JetUC.browserInfo";
import template from "../templates/login_default.html";
import loginAborted from "../templates/login_IE.html";
import utilsBrowser from "./utils/browser";
import utilsSocket from "./utils/socket";

class Login {
    constructor() {
        this._client = null;
        this._connected = false;

        // ELEMENTS
        this._form = null;
        this._inputs = null;

        this._failed = null;
    }

    /**
   * @param options
   * Properties supported:
   * title > title of the login tab
   * info > text or html string to extent the information content of the login page
   * logoPath > Display a logo for the login page. Crucial, each login have to have an image
   * sponsorLogoPaths > Array of sponsor logos added to the login page
   * clusterConfigPath: use a different cluster config path, if omitted the current host is used
   * cachingClusterURL: use a different caching cluster url, if omitted the current host is used
   * suppressIE: option to not allow the user to login if using IE. recommondation to use chrome, firefox, opera or edge displayed instead.
   */
    init(options) {
        const splitLocation = location.pathname.split("/");
        const sportPath = splitLocation[2];
        const potentialSportCode = sportPath.toLowerCase();
        let authenticationId = getAuthenticationId();

        let title;
        let logoPath;
        if (potentialSportCode === "sjp" && splitLocation[3] === "men") {
            logoPath = "general/img/logos/sport/sjp.png";
            title = "MIS Ski Jumping Men";
        } else if (potentialSportCode === "sjp" && splitLocation[3] === "women") {
            title = "MIS Ski Jumping Women";
            logoPath = "general/img/logos/sport/sjp.png";
        } else if (potentialSportCode === "swm" && splitLocation[3] === "isl") {
            title = "MIS Swimming";
            logoPath = "general/img/logos/sport/swm.png";
        } else if (!CIS_LocalConfig.LoginTitle && potentialSportCode.length === 3) {
            title = "MIS " + Object.values(SPORTS).find(x => x.sportCode === potentialSportCode).name;
            logoPath = `general/img/logos/sport/${potentialSportCode}.png`;
        } else {
            title = "MIS " + (CIS_LocalConfig.LoginTitle || "Login");
            logoPath = "general/img/logos/MIS_logo.svg";
        }

        let authApp = "/node/authenticate?app=" + authenticationId;

        document.title = title;

        const bodyElement = document.querySelector("body");
        bodyElement.innerHTML = template;

        if (options.suppressIE && isInternetExplorer()) {
            bodyElement.querySelector(".content-col-left").innerHTML = loginAborted;
        } else {
            this._form = document.querySelector(".login-form_ctn form");
            this._inputs = document.querySelectorAll("input");

            this._connect(options);

            this._form.action = authApp;

            this._loginFailed();
        }

        this._autofillFixForChrome();

        document.querySelector("#sport-select_content .sport").innerText =
            title || "";
        document.querySelector("#additional-info").innerHTML = options.info || "";

        if (logoPath) {
            document.querySelector("#sport-select_content img").src =
        logoPath;
        } else {
            document.querySelector("#sport-select_content img").src = "";
        }
        if (options.sponsorLogoPaths) {
            this._injectSponsorLogos(options.sponsorLogoPaths);
        }
    }

    _connect(options) {
        const supportWS = utilsSocket.checkWebSocket();
        const supportCookies = navigator.cookieEnabled;

        const statusWSElement = document.querySelector(".conn-support span");
        const statusWSSuppMsg = document.querySelector(".conn-support label");
        const statusCookieElement = document.querySelector(".cookies-support span");
        const statusCookieMsg = document.querySelector(".cookies-support label");
        const statusWSConnElem = document.querySelector(".conn-state span");
        const statusWSConnMsg = document.querySelector(".conn-state label");
        const userMsgElem = document.querySelector(".login_connectivity p");

        window.setTimeout(() => {
            let requirementsCheck = true;
            let errorMsg = "<ul class='error-list'>";

            if (supportCookies) {
                statusCookieElement.className = "icon icon--success";
                statusCookieMsg.innerText = "Cookies are enabled.";
            } else {
                statusCookieElement.className = "icon icon--failed";
                statusCookieMsg.innerText = "Cookies are not enabled.";
                errorMsg = `${errorMsg}<li class='error-item'>Cookies need to be enabled in your browser in order to login. </li>`;
                requirementsCheck = false;
            }

            if (supportWS) {
                statusWSElement.className = "icon icon--success";
                statusWSSuppMsg.innerText = "Connectivity test successful.";

                // OnConnection
                utilsSocket.checkSocketConnection({
                    OnConnected: () => {
                        console.info("Websocket connected.");

                        // Disconnect right after
                        this._connected = true;
                        this._client.disconnect();

                        this._form.className = "login-form_ctn enabled";
                        statusWSConnMsg.innerText = "Connection established.";
                        statusWSConnElem.className = "icon icon--success";

                        for (let i = 0, len = this._inputs.length; i < len; i++) {
                            this._inputs[i].disabled = false;
                        }
                        this._isRequirementsCheckFailed(requirementsCheck, errorMsg);
                    },
                    OnFailure: () => {
                        requirementsCheck = false;

                        statusWSConnElem.className = "icon icon--failed";
                        statusWSConnMsg.innerText = "Connection failed.";

                        errorMsg = `${errorMsg}<li class="error-item>">Data connection could not be established. <br>Proxy server or special vpn network configuration may result in an unsuccessful requirements check</li>`;

                        this._isRequirementsCheckFailed(requirementsCheck, errorMsg);
                    },
                    configPath: options.clusterConfigPath,
                    cachingClusterURL: options.cachingClusterURL,
                });
            } else {
                requirementsCheck = false;

                statusWSElement.className = "icon icon--failed";
                statusWSConnElem.className = "icon icon--failed";
                statusWSConnMsg.innerText = "Connection failed.";
                statusWSSuppMsg.innerText = "Connectivity test failed.";

                errorMsg = `${errorMsg}<li class="error-item>">Data connection could not be established. <br>Proxy server or special vpn network configuration may result in an unsuccessful requirements check</li>`;
                this._isRequirementsCheckFailed(requirementsCheck, errorMsg);
            }
        }, 1000);
    }

    _isRequirementsCheckFailed(success, errorMsg) {
        const errorMessageElem = document.querySelector(".login_error");
        const userMsgElem = document.querySelector(".login_connectivity p");

        if (success) {
            userMsgElem.innerText = "Requirements check successful.";
        } else {
            errorMessageElem.innerHTML = `Requirements Check failed. ${errorMsg}</ul>`;
            userMsgElem.innerText = "Requirements check failed.";
        }
    }

    _getParameterByName(name) {
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        const regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
            results = regex.exec(location.search);
        return results === null
            ? ""
            : decodeURIComponent(results[1].replace(/\+/g, " "));
    }

    _loginFailed() {
        this._failed = this._getParameterByName("msg");
        const errorWrapper = document.querySelector("header .login_error");
        if (this._failed) {
            errorWrapper.innerHTML = `<p class="error-message">${this._failed}</p>`;
        } else {
            const elem = document.querySelector("header .error-message");
            if (elem !== null) {
                errorWrapper.removeChild(elem);
            }
        }
    }

    _autofillFixForChrome() {
        if (utilsBrowser.isChrome()) {
            document.body.addEventListener(
                "submit",
                e => {
                    e.preventDefault();
                    setTimeout(() => {
                        this._form.submit();
                    }, 1);
                },
                false
            );
        }
    }

    _injectSponsorLogos(logoPathsArray) {
        const sponsorContainer = document.querySelector(".sponsors");

        if (sponsorContainer && logoPathsArray.length > 0) {
            const fragment = document.createDocumentFragment();

            for (let i = 0, len = logoPathsArray.length; i < len; i++) {
                let img = document.createElement("img");
                img.src = logoPathsArray[i];
                fragment.appendChild(img);
            }

            sponsorContainer.appendChild(fragment);
        }
    }

    /** GETTER SETTER **/

    get client() {
        return this._client;
    }

    set client(val) {
        this._client = val;
    }

    get connected() {
        return this._connected;
    }

    set connected(val) {
        this._connected = val;
    }
}

export default Login;
